"use client";

import { ErrorPage } from "$src/components/error-page/error-page";
import { richtextFragment } from "$src/fragments/richtext";
import { sanity } from "$src/lib/sanity";
import { useEffect, useState } from "react";
import groq from "groq";
import type { SanityImageAssetDocument } from "next-sanity";
import type Error from "next/error";

type notFoundDataType = {
  notFoundIcon: {
    asset: SanityImageAssetDocument;
  };
  notFoundText: string;
};

export default function NotFoundLandingPage(error: Error) {
  const [notFoundData, setNotFoundData] = useState<notFoundDataType>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await sanity.fetch(groq`*[_type == "error"][0]{
          ...,
          notFoundText[]{
            ${richtextFragment}
          }
        }`);
        setNotFoundData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [error]);

  return (
    notFoundData && (
      <ErrorPage
        icon={notFoundData.notFoundIcon.asset}
        text={notFoundData.notFoundText}
        error={error}
      />
    )
  );
}
